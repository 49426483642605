import React, {useContext} from 'react';

import {MyAccountMenu} from '@eidsivabredband/react-component-ui-package';
import {EbAuthenticatedAppContext, useAuthentication} from '@eidsivabredband/app-framework';

export function LabelServiceAccountMenu() {
    const { context } = useContext(EbAuthenticatedAppContext);
    const client = useAuthentication();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleSignIn = () => {
        if (!context) return;
        client.login(context.authConfig?.scopes || []);
    };

    const handleSignOut = () => {
        if (!context) return;
        client.logout(context.idToken || '');
    };

    return (
        <MyAccountMenu
            isAuthenticated={!!context.idToken}
            firstName={client.getActiveUser().idTokenClaims?.given_name}
            lastName={client.getActiveUser().idTokenClaims?.family_name}
            email={client.getActiveUser().idTokenClaims?.emails?.[0]}
            handleSignIn={handleSignIn}
            handleSignOut={handleSignOut}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
        />
    );
}
