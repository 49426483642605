/**
 * Formats a number into norwegian currency
 *
 * @param {number} value
 * @returns
 */
export const toCurrency = (value: number) => {
    return value.toLocaleString('nb-NO', {
        style: 'currency',
        currency: 'NOK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 * Formats a date object into norwegian date
 *
 * @param {Date} value
 * @returns
 */
export const toDate = (value: Date) => {
    return value.toLocaleDateString('nb-no', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

/**
 * Formats a date object into norwegian datetime
 *
 * @param {Date} value
 * @returns
 */
export const toDateTime = (value: Date) => {
    const dateTime = `${value.toLocaleDateString('nb-no', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })} - ${value.toLocaleTimeString('nb-no')}`;
    return dateTime;
};
