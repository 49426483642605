import {Route, Routes} from 'react-router-dom';
import NumberSeriesList from './views/number-series/NumberSeriesList';

// mui
import Reserve from './views/reservation/Reserve';
import {Layout} from './Layout';

function App () {

    return (
        <Routes>
            <Route path="*" element={<Layout />}>
                <Route index element={<Reserve />} />
                <Route path="number-series" element={<NumberSeriesList />} />
                <Route path="logout-callback" element={<div />} />
            </Route>
        </Routes>
    );
};

export default App;