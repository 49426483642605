import React, {createContext, useReducer} from 'react';
import Reducer from './reducer';

const initialState: any = {
    fullname: null,
    email: null,
    roles: [],
    companies: [],
};

export const UserContext = createContext(initialState);

type UserContextProviderProps = {
    children?: React.ReactNode;
};


const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }: UserContextProviderProps ) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>;
};

export default UserContextProvider;