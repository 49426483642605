import {useContext, useState} from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    OutlinedInput,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {Clear, Search} from '@mui/icons-material';
import {GridToolbarColumnsButton, GridToolbarContainer} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ReserveNumberSeries from "../../reservation/ReserveNumberSeries";
import {UserContext} from "../../../context/UserContextProvider";
import {NumberSeries} from "../../../interfaces/ILabelService";
import CloseButton from "../../../components/styles/CloseButton";

interface ToolbarProps {
    value: string;
    clearSearch: () => void;
    onChange: () => void;
    updateGrid: () => any;
    onReserve: (numberSeries: NumberSeries[]) => void;
}

function Toolbar({value, clearSearch, onChange, updateGrid, onReserve}: ToolbarProps) {
    const [openReserveNumberSeries, setOpenReserveNumberSeries] = useState(false);
    const [user] = useContext(UserContext);
    const isEmployee = (user.roles.includes("Employee"));
    const isProjectLeader = (user.roles.includes("ProjectLeader"));
    const isEntrepreneur = (user.roles.includes("Entrepreneur"));

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

    const searchConfig = isMatch ?
        {
            borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px'  , borderTopLeftRadius: '0px'
        } :
        {
            borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px',
            borderTopRightRadius: '12px', borderTopLeftRadius: '12px'
        };

    const handleOpenReserveNumberSeries = () => {
        setOpenReserveNumberSeries(true);
    };

    const handleCloseReserveNumberSeries = () => {
        setOpenReserveNumberSeries(false);
    };

    const handleCloseAndUpdateReserveNumberSeries = (numberSeries: NumberSeries[]) => {
        setOpenReserveNumberSeries(false);
        updateGrid();
        onReserve(numberSeries);
    }

    return (
        <>
            <OutlinedInput
                color="primary"
                fullWidth
                placeholder='e.g. "EFK100000" eller "Lillehammer"'
                value={value}
                onChange={onChange}
                style={searchConfig}
                startAdornment={
                    <InputAdornment position="start">
                        <Search color="primary" />
                    </InputAdornment>
                }
                endAdornment={
                    <IconButton
                        size="small"
                        onClick={clearSearch}
                    >
                        <Clear fontSize="small" />
                    </IconButton>
                }
            />
            <GridToolbarContainer>
                <GridToolbarColumnsButton
                    style={{
                        whiteSpace: 'pre-wrap',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }}
                />

                {(isEmployee || isProjectLeader || isEntrepreneur) &&
                    <Button
                        sx={{
                            marginLeft: "auto",
                            fontWeight: "bold",
                            fontSize: '16px',
                        }}
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={handleOpenReserveNumberSeries}
                    >
                        <AddIcon fontSize="small"/>
                        Reservér
                    </Button>
                }

            </GridToolbarContainer>

            { openReserveNumberSeries &&
                <Dialog
                    open={openReserveNumberSeries}
                    onClose={handleCloseReserveNumberSeries}
                    PaperProps={{
                        style: {
                            margin: 0,
                            width: "calc(100% - 0px)",
                            backgroundColor: 'white'
                        }
                    }}
                    scroll="paper"
                >
                    <DialogTitle>
                        <CloseButton onClose={handleCloseReserveNumberSeries}/>
                    </DialogTitle>
                    <DialogContent style={{ padding: 0 }}>
                        <ReserveNumberSeries onReserve={handleCloseAndUpdateReserveNumberSeries}/>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default Toolbar;