import {alpha, Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography, useTheme} from "@mui/material";
import {NumberSeries} from "../../interfaces/ILabelService";
import {useCallback, useContext, useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {UserContext} from "../../context/UserContextProvider";
import CloseButton from "../../components/styles/CloseButton";
import {useSnackbar} from "notistack";
import {toDate} from "../../utils/ValueFormatters";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConfirmDialog from "./detailsComponents/ConfirmDialog";
import {AcceptIcon, InvalidIcon, NoChangeIcon} from "./detailsComponents/ChangeIcons";
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";

interface NumberSeriesProps {
    numberSeries: NumberSeries;
    open: boolean;
    onClose: () => void;
    onUpdate: () => void;
    onDelete: () => void;
}

function NumberSeriesDetails({numberSeries, open, onClose, onUpdate, onDelete}: NumberSeriesProps){
    const [newLastNumber, setNewLastNumber] = useState<number | null>(null);
    const [comment, setComment] = useState<string>("");
    const [commentChanged, setCommentChanged] = useState(false);
    const [numberChanged, setNumberChanged] = useState(false);
    const [commentValid, setCommentValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const { context } = useContext(EbAuthenticatedAppContext);
    const [user] = useContext(UserContext);
    const theme = useTheme();
    const [checked, setChecked] = useState(false);
    const [openDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
    const [openConfirmChangeDialog, setOpenConfirmChangeDialog] = useState(false);
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const isEmployee = user.roles?.includes("Employee");

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleLastNumberChange = (e: any) => {
        setNewLastNumber(parseInt(e.target.value))
    }

    const handleCommentChange = (e: any) => {
        setComment(e.target.value)
    }

    const handleCloseNumberSeriesDialog = useCallback(() => {
        setNumberChanged(false);
        setNumberValid(false);
        setCommentChanged(false);
        setCommentValid(false);

        setChecked(false);
        onClose();

    }, [onClose]);

    const handleOnClickChangeButton = () => {
        setOpenConfirmChangeDialog(true);
    }

    const handleOnClickDeleteButton = () => {
        setOpenConfirmDeleteDialog(true);
    }

    const handleChangeNumberSeries = () => {
        if (!context.restApiClient.getAccessToken()) return;

        if (typeof comment === 'string' && comment.length > 0) {
            numberSeries.comments = comment;
        }

        let lastNumber: any;
        newLastNumber === numberSeries.lastNumber ? lastNumber = null : lastNumber = newLastNumber;

        context.restApiClient
            .buildRequest(`api/number-series/update${lastNumber ? `?newLastNumber=${lastNumber}` : ""}`)
            .serializeToJsonContent(numberSeries).putAsync()
            .then(() => {
                if (onUpdate !== undefined) {
                    onUpdate();
                    setOpenConfirmChangeDialog(false);
                    setChecked(false);
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    <Typography variant="body2">
                        Kunne ikke endre nummerserien. Prøv igjen eller kontakt kundeservice.
                    </Typography>,
                    { variant: "error" }
                )
            });
    }

    const handleDeleteNumberSeries = () => {
        numberSeries.user = user.username;
        context.restApiClient
            .buildRequest(`api/number-series/delete`)
            .serializeToJsonContent(numberSeries).putAsync()
            .then(() => {
                if (onDelete !== undefined) {
                    onDelete();
                    setOpenConfirmDeleteDialog(false);
                    setChecked(false);
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    <Typography variant="body2">
                        Kunne ikke slette nummerserien. Prøv igjen eller kontakt kundeservice.
                    </Typography>,
                    { variant: "error" }
                )
            });
    }

    const renderCommentIcon = () => {
        if (commentChanged) {
            return commentValid ? <AcceptIcon /> : <InvalidIcon />;
        }
        return <NoChangeIcon />;
    };

    const renderNumberIcon = () => {
        if(newLastNumber === null)
            return <NoChangeIcon />;

        if (numberChanged) {
            return numberValid ? <AcceptIcon /> : <InvalidIcon />;
        }

        return <NoChangeIcon />;
    };

    useEffect(() => {
        if(open){
            setComment(numberSeries.comments);
            setNewLastNumber(numberSeries.lastNumber);
        }
    },[open]);

    useEffect(() => {
        const commentChanged = comment !== numberSeries.comments;
        const numberChanged = newLastNumber !== numberSeries.lastNumber;

        commentChanged ? setCommentChanged(true) : setCommentChanged(false);
        numberChanged ? setNumberChanged(true) : setNumberChanged(false);

        const commentValid = /\S/.test(comment) && commentChanged
        const numberValid = (newLastNumber && (newLastNumber >= numberSeries.firstNumber && newLastNumber < numberSeries.lastNumber)) && numberChanged;

        commentValid ? setCommentValid(true) : setCommentValid(false);
        numberValid ? setNumberValid(true) : setNumberValid(false);

        const bothFieldsChanged = commentChanged && numberChanged;
        const oneFieldChanged = commentChanged || numberChanged;

        const bothFieldsValid = commentValid && numberValid;
        const oneFieldValid = commentValid || numberValid;

        const buttonDisabled = !(bothFieldsChanged && bothFieldsValid ||
                oneFieldChanged && oneFieldValid) ||
            (bothFieldsChanged && !bothFieldsValid);
        setButtonDisabled(buttonDisabled);
    }, [comment, newLastNumber]);

    return (
        <Dialog
            open={open}
            onClose={handleCloseNumberSeriesDialog}
            PaperProps={{
                style: {
                    margin: 0,
                    width: "calc(100% - 0px)",
                }
            }}
        >
            <DialogTitle>
                {numberSeries.companyPrefix}{numberSeries.zeroFirstNumber} - {numberSeries.zeroLastNumber}
                <CloseButton onClose={handleCloseNumberSeriesDialog}/>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Collapse in={!checked} >
                        <Grid
                            container
                            item
                            bgcolor={alpha(theme.palette.primary.light, 0.24)}
                            p={2}
                            borderRadius={"24px"}
                        >
                            <Grid container item>
                                <Grid item xs={12} sm="auto">
                                    <Typography
                                        variant="body2"
                                        fontWeight={600}
                                    >
                                        Bedrift:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm="auto" style={{marginLeft: 'auto'}}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {numberSeries.companyName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={12} sm="auto">
                                    <Typography
                                        variant="body2"
                                        fontWeight={600}
                                    >
                                        Registrert av:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm="auto" style={{marginLeft: 'auto'}}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {numberSeries.user}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={12} sm="auto">
                                    <Typography
                                        variant="body2"
                                        fontWeight={600}
                                    >
                                        Opprettet:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm="auto" style={{marginLeft: 'auto'}}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {toDate(new Date(numberSeries.dateReserved as Date))}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item style={{ wordWrap: "break-word" }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        fontWeight={600}
                                    >
                                        Kommentar:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{marginLeft: 'auto'}}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word"
                                        }}
                                    >
                                        {numberSeries.comments}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>
                    {isEmployee &&
                        <Box width={"100%"}>
                            <Box mt={2} textAlign="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    title={checked ? "Tilbake til infoen" : "Mulighet for endring og sletting"}
                                    aria-label="endring"
                                    onClick={handleChange}
                                    endIcon={checked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                >
                                    {checked ? "Serieinfo" : "Rediger"}
                                </Button>
                            </Box>
                            <Collapse in={checked} >
                                <Grid
                                    container
                                    item
                                    mt={2}
                                    bgcolor={alpha(theme.palette.primary.light, 0.24)}
                                    p={2}
                                    borderRadius={"24px"}
                                >
                                    {(numberSeries.lastNumber !== numberSeries.firstNumber) &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body2"
                                                    fontWeight={600}
                                                >
                                                    Nummerserie: { renderNumberIcon() }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} mt={1} mb={1}>
                                                <Typography
                                                    display={"inline"}
                                                    variant="body2"
                                                >
                                                    {`${numberSeries.companyPrefix}${numberSeries.zeroFirstNumber} - `}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    type="number"
                                                    id={numberSeries.zeroLastNumber}
                                                    name={numberSeries.zeroLastNumber}
                                                    defaultValue={numberSeries.zeroLastNumber}
                                                    placeholder={numberSeries.zeroLastNumber}
                                                    onChange={handleLastNumberChange}
                                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                    style={{
                                                        display: "inline-block",
                                                        maxWidth: "110px",
                                                        verticalAlign: "middle",
                                                        borderRadius: "24px",
                                                        marginTop: '-2px',
                                                        backgroundColor: "#F7F7FF"
                                                    }}
                                                    inputProps={{
                                                        min: 0,
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Kommentar: { renderCommentIcon() }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="outlined-basic"
                                            defaultValue={numberSeries.comments}
                                            name={numberSeries.comments}
                                            fullWidth
                                            multiline={true}
                                            rows={5}
                                            sx={{borderRadius: "24px", backgroundColor: "#F7F7FF"}}
                                            onChange={handleCommentChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item mt={2}>
                                    <Grid item>
                                        <Button
                                            disabled={buttonDisabled}
                                            variant="contained"
                                            color="secondary"
                                            title="Endre nummerserien"
                                            aria-label="update"
                                            onClick={handleOnClickChangeButton}
                                        >
                                            Endre
                                        </Button>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            title="Slett nummerserien"
                                            aria-label="delete"
                                            onClick={handleOnClickDeleteButton}
                                        >
                                            Slett serie
                                        </Button>
                                        <ConfirmDialog
                                            open={openDeleteDialog}
                                            onClose={() => setOpenConfirmDeleteDialog(false)}
                                            onConfirm={handleDeleteNumberSeries}
                                            title="Ønsker du virkelig å slette hele serien?"
                                        />

                                        <ConfirmDialog
                                            open={openConfirmChangeDialog}
                                            onClose={() => setOpenConfirmChangeDialog(false)}
                                            onConfirm={handleChangeNumberSeries}
                                            title="Du er i ferd med å endre følgende:"
                                            content={
                                                <>
                                                    {newLastNumber && numberChanged && numberValid && (
                                                        <Box mb={1}>
                                                            <Typography variant="body2" fontWeight={600}>Seriens siste nummer: </Typography>
                                                            <Typography variant="body2">
                                                                {newLastNumber}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {comment && commentChanged && commentValid && (
                                                        <Box>
                                                            <Typography variant="body2" fontWeight={600}>Kommentar:</Typography>
                                                            <Typography variant="body2" style={{whiteSpace: "pre-line", wordWrap: "break-word" }}>
                                                                {comment}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Box>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NumberSeriesDetails;