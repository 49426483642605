import {Box, Button, Card, CardActions, CardContent, Container, Link, Typography} from "@mui/material";
import {ReactComponent as Chatbot} from '../../assets/illustrations/Felles/chatbot.svg';
import React from "react";
import {IconOnCircle} from "@eidsivabredband/react-component-ui-package";


function RestrictedAccess({message}: any) {

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <Container maxWidth="sm">
            <Card sx={{ mt: 2, p: 2 }} elevation={0}>
                <CardContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <IconOnCircle color="primary.light" icon={<Chatbot />}  size={"LARGE"} />
                        <Typography variant="h4" gutterBottom>
                            Noe gikk galt!
                        </Typography>
                        <Typography align="center" color="text.secondary" paragraph>
                            {message} Hvis problemet vedvarer, kan du kontakte vårt{' '}
                            <Link
                                href="https://eidsiva.net/kundeservice/kontakt/"
                                underline="always"
                            >
                                kundeservice
                            </Link>{' '}
                            eller prøve å laste inn siden på nytt.
                        </Typography>
                    </Box>
                </CardContent>

                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={refreshPage}
                        size="small"
                        disableElevation
                    >
                        Last inn på nytt
                    </Button>
                </CardActions>
            </Card>
        </Container>
    )
}

export default RestrictedAccess;