import { useMsal } from '@azure/msal-react';
import { Box, Button, Divider, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContextProvider';
import { Company, CompanyArea, NumberSeries, Prefix, ReserveNumberSeriesModel } from '../../interfaces/ILabelService';
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";

interface ReserveProps {
    onReserve?: (numberSeries: NumberSeries[]) => void;
}

function ReserveNumberSeries({ onReserve }: ReserveProps) {
    const { context } = useContext(EbAuthenticatedAppContext);
    const [user] = useContext(UserContext);
    const { instance, accounts } = useMsal();

    const [reservations, setReservations] = useState<ReserveNumberSeriesModel[]>([]);
    const [numberValues, setNumberValues] = useState<{ [name: string]: number | undefined }>({});
    const [selectedCompanyArea, setSelectedCompanyArea] = useState<string>("");
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const [comment, setComment] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const isEntrepreneurOnly = user.roles.includes("Entrepreneur") && !user.roles.includes("Employee") && !user.roles.includes("ProjectLeader");

    const handleClearAllValues = () => {
        setNumberValues({});
        setReservations([]);
        setComment('');
    }

    const handleCommentsChange = (e: any) => {
        setComment(e.target.value);
    };

    const handleCompanyAreaChange = (event: SelectChangeEvent<string>) => {
        handleClearAllValues();

        const companyAreaName = event.target.value;
        const selectedCompany = user.companies.find((company: Company) =>
            company.companyAreas.find(area => area.areaName === companyAreaName));

        setSelectedCompanyArea(companyAreaName);
        setSelectedCompany(selectedCompany.companyName);
    };

    const handleNumberOfServicesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, valueAsNumber } = event.target;
        updateValue(name, valueAsNumber);
    }

    const updateValue = (name: string, value: number) => {
        if (isEntrepreneurOnly) {
            if (value > 999) {
                value = 999;
            }
        }

        setNumberValues(numberValues => ({ ...numberValues, [name]: value }));

        let tmpReservations = [...reservations];

        const index = tmpReservations.findIndex((i) => i.companyPrefix === name);

        if (index !== -1) {
            if (value <= 0 || !value) {
                setReservations(tmpReservations => {
                    return tmpReservations.filter((reservation, i) => i !== index);
                });
            }
            else {
                tmpReservations[index].lengthOfSeries = value;
                setReservations(tmpReservations);
            }
        } else {
            if (value <= 0 || !value) return;

            setReservations((reservations) => [...reservations, {
                companyPrefix: name,
                lengthOfSeries: value,
                comments: "",
                companyName: selectedCompany,
                user: user.email ? user.email
                    : accounts[0].username ? accounts[0].username
                        : user.fullname
            }]);
        }
    };

    const handleSubmit = async (e: any) => {
        e?.preventDefault();

        if (reservations.length > 0) {
            reservations.forEach((reservation) => {
                if (reservation.lengthOfSeries > 0) {
                    reservation.comments = comment;
                } else {
                    reservations.splice(reservations.indexOf(reservation), 1);
                }
            });

            const response = await context.restApiClient
                .buildRequest('/api/number-series/reserve')
                .serializeToJsonContent(reservations).
                deserializeResponseToJson<NumberSeries[]>()
                .putAsync()

            if (response.json) {
                handleClearAllValues();
                if (onReserve !== undefined) {
                    onReserve(response.json);
                }
            } else if (response.error) {
                enqueueSnackbar(
                    <Typography variant="body2">Kunne ikke reservere nummerserien. Prøv igjen eller kontakt
                        kundeservice.</Typography>,
                    {variant: "error"}
                )
            }
        }
    };

    useEffect(() => {
        if (user.companies?.length > 0) {
            var firstCompany = user.companies[0];

            setSelectedCompanyArea(firstCompany.companyAreas[0]?.areaName)
            setSelectedCompany(firstCompany.companyName);
        }
    }, [user.companies])

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid
                        container
                        marginBottom={2}
                        marginTop={3}
                        display={'flex'}
                        flexWrap={'nowrap'}
                        alignItems={'center'}
                    >
                        <Grid item xs={12} textAlign={'center'} paddingLeft={'20px'} paddingRight={'20px'}>
                            <Typography component="span" ><b>Reservér nummerserier </b></Typography>
                            {!(user.companies.length === 1 && user.companies[0].companyAreas.length === 1) &&
                                <Typography component="span" marginLeft={1}>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={selectedCompanyArea}
                                        onChange={handleCompanyAreaChange}
                                    >
                                        {user.companies.map((company: Company) => (
                                            company.companyAreas?.map((area: CompanyArea) => (
                                                <MenuItem key={area.areaName} value={area.areaName}>
                                                    {area.areaName}
                                                </MenuItem>
                                            ))
                                        ))}
                                    </Select>
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                    {user.companies.map((company: Company) => (
                        company.companyAreas.find((area: CompanyArea) =>
                            area.areaName === selectedCompanyArea)?.prefixes.map((prefix: Prefix, index: any) => (
                                <Box key={index}>
                                    <Grid
                                        container
                                        paddingLeft={'20px'}
                                        paddingRight={'20px'}
                                        bgcolor={index % 2 === 0 ? "whitesmoke" : "white"}
                                        display={'flex'}
                                        flexWrap={'nowrap'}
                                        alignItems={'center'}
                                    >
                                        <Grid item minWidth={'80px'} xs={3}>
                                            <Typography variant="body2"><b>{prefix.name}</b></Typography>
                                        </Grid>
                                        <Grid item minWidth={'100px'} xs={8}>
                                            <Typography variant="body2">{prefix.description}</Typography>
                                        </Grid>
                                        <Grid item minWidth={'65px'} xs={1}>
                                            <TextField
                                                style={{ alignItems: 'right' }}
                                                variant="standard"
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: 'right' },
                                                    },
                                                    disableUnderline: true
                                                }}
                                                size="small"
                                                margin="normal"
                                                type="number"
                                                id={prefix.name}
                                                name={prefix.name}
                                                onChange={handleNumberOfServicesChange}
                                                placeholder="0"
                                                inputProps={{ min: 0, }}
                                                value={numberValues[prefix.name] || ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                    ))
                    }
                    <Grid container paddingLeft={'20px'} paddingRight={'20px'}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: '8px' }}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                id="comments"
                                name="comments"
                                label="Kommentar"
                                value={comment}
                                multiline={true}
                                rows={3}
                                fullWidth
                                required
                                onChange={handleCommentsChange}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="row" alignItems="center" marginBottom={'20px'}>
                            <Button
                                disabled={reservations.length <= 0 || !/\S/.test(comment)}
                                type="submit"
                                variant="contained"
                                color="primary">
                                Reserver
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ReserveNumberSeries;