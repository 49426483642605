import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NumberSeries } from "../../interfaces/ILabelService";
import ReserveNumberSeries from "./ReserveNumberSeries";
import { useSnackbar } from 'notistack';

function Reserve() {
    const { enqueueSnackbar } = useSnackbar();
    
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

    const config = isMatch ?
    {
        paddingLeft: 0, paddingRight: 0,
    } : {}

    const reserveConfig = isMatch ? 
    { 
        border: '1px solid lightgrey',
    } : 
    {
        border: '1px solid lightgrey',
        borderRadius: '24px',
    };
    
    const handleReserve = (numberSeries: NumberSeries[]) => {
        enqueueSnackbar(
            <Box>
                <Typography 
                    sx={{textDecoration: 'underline'}} 
                    variant="body2"
                >
                    Du har reservert:
                </Typography>
                {numberSeries?.map((series: NumberSeries) => (
                    <Typography key={series.numberSeriesId} variant="body2">
                        {series.numberSeriesId} - {series.zeroLastNumber}
                    </Typography>
                ))}
            </Box>
            , { variant: "success" }
        )
    }

    return (
        <Container maxWidth="md" style={config}>
            <Box style={reserveConfig}>
                <ReserveNumberSeries onReserve={handleReserve}/>
            </Box>
        </Container>
    )
}

export default Reserve;
