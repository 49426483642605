import {ReactComponent as WebPage} from '../../assets/illustrations/Konsern/nettside.svg';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridRowModel,
    GridRowParams,
    GridValueFormatterParams
} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from "react";
import {NumberSeries} from "../../interfaces/ILabelService";
import {escapeRegExp} from "../../utils/Formatters";
import Toolbar from "./toolbar/Toolbar";
import {toDate} from "../../utils/ValueFormatters";
import NumberSeriesDetails from "./NumberSeriesDetails";
import CustomPagination from "./toolbar/Pagination";
import {Box, Container, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useSnackbar} from "notistack";
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";
import {Hero} from "@eidsivabredband/react-component-ui-package";

function NumberSeriesList() {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

    const config = isMatch ? { paddingLeft: 0, paddingRight: 0 } : {};
    const dgConfig = isMatch ?
        {
            borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px', borderTopLeftRadius: '0px'
        } : {};

    const [allNumberSeries, setAllNumberSeries] = useState<NumberSeries[]>([]);
    const [filteredNumberSeries, setFilteredNumberSeries] = useState<NumberSeries[]>([]);
    const [selectedNumberSeries, setSelectedNumberSeries] = useState<NumberSeries | any>();
    const [openNumberSeries, setOpenNumberSeries] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [loading, setIsLoading] = useState(true);
    const { context } = useContext(EbAuthenticatedAppContext);
    const { enqueueSnackbar } = useSnackbar();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        companyPrefix: false,
        zeroFirstNumber: false,
        zeroLastNumber: false,
        user: false,
        dateReserved: false,
    });

    const handleSaveColumnVisibility = (params : GridColumnVisibilityModel) => {
        localStorage.setItem("ColumnVisibility", JSON.stringify(params));
    }

    const columns: GridColDef[] = [
        { field: 'fullNumberSeries', headerName: 'Serie', minWidth: 220, flex: 1, valueGetter: getFullLabel,
            renderCell: (params) =>  {
                return (
                    <span style={{fontWeight: 'bold', color: theme.palette.primary.dark}}>
                        {params.value}
                    </span>
                )
            }
        },
        { field: 'companyPrefix', headerName: 'Prefix', minWidth: 85, width: 85 },
        { field: 'zeroFirstNumber', headerName: 'Fra', minWidth: 85, width: 85 },
        { field: 'zeroLastNumber', headerName: 'Til', minWidth: 85, width: 85 },
        { field: 'companyName', headerName: 'Bedrift', minWidth: 85, width: 85 },
        { field: 'comments', headerName: 'Kommentar', minWidth: 150, flex: 1 },
        { field: 'user', headerName: 'Bestiller', minWidth: 150, flex: 1 },
        { field: 'dateReserved', headerName: 'Dato', minWidth: 110, width: 110,
            valueFormatter: (params: GridValueFormatterParams) => params.value ? toDate(new Date(params.value as Date)) : ' ', },
    ];

    function getFullLabel(params: any) {
        return `${params.row.companyPrefix || ''}${params.row.zeroFirstNumber || ''} - ${
            params.row.zeroLastNumber || ''
        }`;
    }

    const handleCloseNumberSeries = () => {
        setOpenNumberSeries(false);
    };

    const handleUpdateAndCloseNumberSeries = () => {
        handleGetAllReservedNumberSeries();
        setOpenNumberSeries(false);

        enqueueSnackbar(
            <Typography variant="body2">Nummerserien er endret.</Typography>,
            { variant: "success" }
        )
    };

    const handleDeleteAndCloseNumberSeries = () => {
        handleGetAllReservedNumberSeries();
        setOpenNumberSeries(false);

        enqueueSnackbar(
            <Typography variant="body2">Nummerserien er slettet.</Typography>,
            { variant: "success" }
        )
    };

    const handleGetReservedSeries = (numberSeries: NumberSeries[]) => {
        enqueueSnackbar(
            <Box>
                <Typography
                    variant="body2"
                    display="block"
                    style={{
                        textDecoration: "underline"
                    }}
                >
                    Du har reservert:
                </Typography>
                {numberSeries?.map((series: NumberSeries) => (
                    <Typography
                        key={series.numberSeriesId}
                        variant="body2"
                        display="block"
                    >
                        {series.numberSeriesId} - {series.zeroLastNumber}
                    </Typography>
                ))}
            </Box>
            , { variant: "success" }
        )
    }

    const handleGetAllReservedNumberSeries = async () => {
        const response = await context.restApiClient
            .buildRequest('/api/number-series/all-reserved').deserializeResponseToJson<NumberSeries[]>().getAsync()
        if (response.json) {
            setAllNumberSeries(response.json);
            setFilteredNumberSeries(response.json);
            setIsLoading(false);
        } else if (response.error) {
            enqueueSnackbar(
                <Typography variant="body2">Kunne ikke hente listen. Prøv igjen eller kontakt
                    kundeservice.</Typography>,
                {variant: "error"}
            )
        }
    };

    const handleGetNumberSeries = async (numberSeriesId: GridRowModel) => {
        const response = await context.restApiClient.buildRequest(`/api/number-series/${numberSeriesId}`).deserializeResponseToJson<NumberSeries>().getAsync()
        if (response.json) {
            setSelectedNumberSeries(response.json);
            setOpenNumberSeries(true);
        } else if (response.error) {
            enqueueSnackbar(
                <Typography variant="body2">Kunne ikke hente nummerserien. Prøv igjen eller kontakt
                    kundeservice.</Typography>,
                {variant: "error"}
            )
        }
    }

    const requestSearch = (searchValue: any) => {
        setSearchText(searchValue);

        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const searchText = searchValue.replace(/[0-9]/g, "").toUpperCase();
        let searchNumber: number;

        if (/\d/.test(searchValue)) {
            searchNumber = parseInt(searchValue.match(/\d+/)[0], 10);
        }

        let filteredRows;
        if (searchValue === "") {
            filteredRows = filteredNumberSeries;
        } else {
            filteredRows = filteredNumberSeries.filter((row: any) => {
                return (
                    (searchNumber >= row.firstNumber &&
                        searchNumber <= row.lastNumber &&
                        (searchText === row.companyPrefix || searchText.length === 0)) ||
                    searchRegex.test(Object.values(row).toString())
                );
            });
        }

        setAllNumberSeries(filteredRows);
    };

    useEffect(() => {
        handleGetAllReservedNumberSeries();
    }, []);

    useEffect(() => {
        const json = JSON.parse(localStorage.getItem("ColumnVisibility") || "{}");

        if(Object.keys(json).length !== 0){
            setColumnVisibilityModel(json);
        }
    }, []);

    return (
        <>
            <Container
                maxWidth={"lg"}
                style={{
                    margin: '40px auto'
                }}>
                <Hero
                    title="Nummerserier"
                    icon={<WebPage />}
                    description="Her ser du alle registrerte nummerserier, og du kan søke på spesifikke nummer og annet relevant informasjon tilknyttet seriene."
                />
            </Container>
            <Container
                maxWidth={"lg"}
                style={config}>
                <DataGrid
                    localeText={{
                        columnsPanelShowAllButton: 'Vis alle',
                        columnsPanelHideAllButton: 'Skjul alle',
                        columnsPanelTextFieldLabel: 'Finn kolonne',
                        columnsPanelTextFieldPlaceholder: 'Finn kolonne',
                        toolbarColumns: 'Kolonner',
                        noRowsLabel: 'Ingen nummerserier funnet',
                    }}
                    sx={dgConfig}
                    loading={loading}
                    getRowId={(numberSeries) => numberSeries.numberSeriesId }
                    onRowClick={(params: GridRowParams) => handleGetNumberSeries(params.row.numberSeriesId)}
                    rows={allNumberSeries}
                    columns={columns}
                    autoHeight
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    rowHeight={45}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => {
                        handleSaveColumnVisibility(newModel);
                        setColumnVisibilityModel(newModel);
                    }}
                    components={{
                        Toolbar: Toolbar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: any) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(""),
                            updateGrid: (handleGetAllReservedNumberSeries),
                            onReserve: (handleGetReservedSeries),
                            top: '-200px'
                        }
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 15 } },
                        sorting: {
                            sortModel: [{ field: 'dateReserved', sort: 'desc' }],
                        },
                    }}
                />
                {selectedNumberSeries &&
                    <NumberSeriesDetails
                        numberSeries={selectedNumberSeries}
                        open={openNumberSeries}
                        onClose={handleCloseNumberSeries}
                        onUpdate={handleUpdateAndCloseNumberSeries}
                        onDelete={handleDeleteAndCloseNumberSeries}
                    />
                }
            </Container>
        </>
    );
}

export default NumberSeriesList;