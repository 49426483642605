import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { useSnackbar } from 'notistack';

function SnackbarCloseButton({ snackbarKey }: any) {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <CloseIcon />
      </IconButton>
    );
  }

export default SnackbarCloseButton;