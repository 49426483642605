import {Link, Outlet, useMatch} from 'react-router-dom';

// mui
import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from './context/UserContextProvider';
import {UserInfo} from './interfaces/ILabelService';
import RestrictedAccess from './components/feedback/RestrictedAccess';
import {Loading} from './components/feedback/Loading';
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";
import {Banner, ErrorWrapper, Header} from '@eidsivabredband/react-component-ui-package';
import {Tab} from "@mui/material";
import {LabelServiceAccountMenu} from "./LabelServiceAccountMenu";

export function Layout () {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [user, dispatch] = useContext(UserContext);
    const { context } = useContext(EbAuthenticatedAppContext);
    const match = useMatch("/");


    const tabs = [<Tab label="Reservér" component={Link} to="/" value="/" />,
        <Tab label="Nummerserier" component={Link} to="/number-series" value="/number-series" />];

    useEffect(() => {
        setLoading(true);
        const fetchUserInfo = async () => {
            const userInfo = await context.restApiClient.buildRequest('/api/userinfo').deserializeResponseToJson<UserInfo>().getAsync()
            if (userInfo.json) {
                dispatch({name: 'fullname', value: userInfo.json.fullname});
                dispatch({name: 'email', value: userInfo.json.email});
                dispatch({name: 'roles', value: userInfo.json.roles});
                dispatch({name: 'orgNumber', value: userInfo.json.userOrgNumber});
                dispatch({name: 'companies', value: userInfo.json.companies});
                setLoading(false);
            } else if (userInfo.error) {
                setErrorMessage("Du har ikke rettigheter inn til merketjenesten.");
            }
        }

        fetchUserInfo();
        setLoading(false);
    }, [context.restApiClient, dispatch]);


    return (
        <>
            <Header tabs={tabs}>
                <LabelServiceAccountMenu />
            </Header>
            {loading ? <Loading /> : !errorMessage ?
                <>
                    {match && <Banner pageTitle="Merke&shy;tjenesten" />}

                    <ErrorWrapper>
                        <Outlet />
                    </ErrorWrapper>
                </>
                :   <RestrictedAccess message={errorMessage} />}
        </>
    );
}
