import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { Tooltip } from '@mui/material';

export function AcceptIcon() {
    return (
        <CheckCircleIcon 
            fontSize="small" 
            style={{verticalAlign:"middle"}} 
            color="success"
        />
    )
}

export function NoChangeIcon() {
    return (
        <Tooltip title="Ingen endring" placement="right">
            <PendingIcon 
                fontSize="small" 
                style={{verticalAlign:"middle"}} 
                color="disabled"
            />
        </Tooltip>
    )
}

export function InvalidIcon() {
    return (
        <Tooltip title="Ugyldig" placement="right">
            <ErrorIcon 
                fontSize="small" 
                style={{verticalAlign:"middle"}} 
                color="error"
            /> 
        </Tooltip>
    )
}