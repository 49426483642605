import React from 'react';
import { ReactComponent as ColorCircle } from '../../assets/logos/eb-colorcircle.svg';

import { Box, Typography } from '@mui/material';

interface Props {
    small?: boolean;
    description?: string;
}

/**
 * ### Loading indicator ###
 * A loading indicator with spinning Eidsiva Bredbånd colorcircle
 *
 * @example <caption>Small loading indicator</caption>
 * ```jsx
 *       <Loading description="Loading data..." small />
 * ```
 * @param description
 * @param small - optional small size
 */
export const Loading = ({ small, description }: Props) => {
    return (
        <Box 
            sx={{
                position: 'fixed', 
                left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
            }}
        >
            <Box
                sx={{
                    '& svg': {
                        height: small ? 32 : 64,
                        width: small ? 32 : 64,
                        m: 2,
                        animationName: 'spin',
                        animationDuration: '1250ms',
                        animationIterationCount: 'infinite',
                        animationTimingFunction: 'linear',
                    },
                    '@keyframes spin': {
                        from: { transform: 'rotate(0deg)' },
                        to: { transform: 'rotate(360deg)' },
                    },
                }}
            >
                <ColorCircle />
            </Box>

            <Typography variant="body1">
                {description ? description : 'Vennligst vent...'}
            </Typography>
        </Box>
    );
};
