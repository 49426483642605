import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material';
import {BrowserRouter} from 'react-router-dom';
import UserContextProvider from './context/UserContextProvider';
import {SnackbarProvider} from 'notistack';
import SnackbarCloseButton from './components/styles/SnackbarCloseButton';
import {
    AuthenticatedAppProvider,
    ConsoleLogger,
    EbAuthenticatedContext,
    IAuthenticationConfig
} from "@eidsivabredband/app-framework";
import {createRoot} from "react-dom/client";
import {lightTheme} from "@eidsivabredband/react-component-ui-package";

const container = document.getElementById('root');
const root = createRoot(container!);

fetch('/api/auth-config').then(async (response: Response) => {
    const json = await response.json();
    const config = json as IAuthenticationConfig;

    root.render(
        <React.StrictMode>
            <ThemeProvider theme={lightTheme}>
                <AuthenticatedAppProvider logger={new ConsoleLogger()} authConfig={config}>
                    <EbAuthenticatedContext>
                        <BrowserRouter>
                            <UserContextProvider>
                                <SnackbarProvider maxSnack={12} style={{borderRadius: '24px'}} autoHideDuration={10000} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
                                    <App />
                                </SnackbarProvider>
                            </UserContextProvider>
                        </BrowserRouter>
                    </EbAuthenticatedContext>
                </AuthenticatedAppProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
