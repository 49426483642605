import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IconProps {
    onClose: () => void;
}

function CloseButton({ onClose }: IconProps ) {

    return (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton;