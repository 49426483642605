import { Padding } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content?: JSX.Element;
}

function ConfirmDialog({ open, onClose, onConfirm, title, content }: ConfirmDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
        >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions style={{
            padding: '24px',
            paddingTop: '0px'
        }}>
            <Button 
                onClick={onConfirm} 
                variant="contained"
                color="secondary"
            >
                Bekreft
            </Button>
            <Button style={{marginLeft: 'auto'}} 
                onClick={onClose} 
                variant="contained"
                color="primary"
            >
                Avbryt
            </Button>
        </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;