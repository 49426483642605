const Reducer = (state: any, update: any) => {
    switch (update.name) {
        case "fullname":
            return {
                ...state,
                fullname: update.value
            };
        case "email":
            return {
                ...state,
                email: update.value
            };
        case "roles":
            return {
                ...state,
                roles: update.value
            };
        case "companies":             
            return {
                ...state,
                companies: update.value
            };
    }
    return {
        ...state
    };
};

export default Reducer;